.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ag-theme-alpine {
  --ag-foreground-color: rgb(0, 0, 0);
  --ag-background-color: rgb(230, 211, 180);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(236, 196, 131);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}

table.svg-table {
  font-size: 9pt;
  border-collapse: collapse; 
  background-color: white;
  border: white solid 10px;
  border-top: 5px;
}

table.svg-table td {
  padding-top: 10px;
}

table.svg-table td + td,
table.svg-table th + th { border-left: 1px solid; width: 60px; }


body~#root div:nth-child(2) {
  opacity: 0 !important;
}